import { FC } from 'react';
import { Typography } from '@mui/material';

export const Logo: FC = () => {
	return (
		<div style={{
      display: 'flex',
      flex: 1,
    }}>
      <div style={{
        background: '#f2533d',
        width: '4px',
        height: '4px',
        left: '3px',
        position: 'relative',
        borderRadius: '50%',
      }}></div>
      <Typography sx={{
        fontFamily: "'Nunito', sans-serif",
        position: 'relative',
        transform: 'rotate(-5deg)',
        fontSize: '15px',
      }}>I</Typography>
      <Typography sx={{
        fontFamily: "'Nunito', sans-serif",
        position: 'relative',
      }}>K</Typography>
      <Typography sx={{
        fontFamily: "'Nunito', sans-serif",
        position: 'relative',
        fontSize: '16px',
        transform: 'rotate(5deg)',
      }}>s</Typography>
    </div>
	)
}