import { FC, useState } from 'react';
import { useEditController, TopToolbar, ListButton, TextInput, Edit, required, ReferenceInput, ImageInput, AutocompleteInput, ArrayInput, SimpleFormIterator, Toolbar, SaveButton, BooleanInput, AutocompleteArrayInput, NumberInput, ReferenceArrayInput, TabbedForm } from 'react-admin';
import { getAuth } from 'firebase/auth';
import { RichTextInput } from 'ra-input-rich-text';
import { ImagePreviewField } from '../../fields/image-preview/image-preview-field';
import { languages } from '../../configs/languages';
import { recTypes } from '../../configs/rec-types';
import { countries } from '../../configs/countries';

export const SmartObjectBookEdit: FC = () => {
  const [recordings, setRecordings] = useState<any>([]);
  const [sampleRecordingList, setSampleRecordingList] = useState<any>([]);

  const [recType, setRecType] = useState<any>([]);
  const { record } = useEditController();
  const auth = getAuth();
  const validate = required();

  if (record?.recordingsList?.length && !recordings.length) {
    record?.recordingsList?.forEach((i: any) => {
      const id = i;
      const name = i;
      recordings.push({ id, name });
    });
    setRecordings(recordings);
  }

  if (record?.sampleRecordingList?.length && !sampleRecordingList.length) {
    record?.sampleRecordingList?.forEach((i: any) => {
      const id = i;
      const name = i;
      sampleRecordingList.push({ id, name });
    });
    setSampleRecordingList(sampleRecordingList);
  }

  if (record?.recType && !recType.length) {
    const id = record?.recType;
    const name = record?.recType;
    recType.push({ id, name });
    setRecType(recType);
  }

  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );  

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <TabbedForm toolbar={toolbar}>
        <TabbedForm.Tab label="summary">
          <TextInput disabled fullWidth label="ID" source="id" variant="standard" />
          <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
          <ArrayInput fullWidth label="DESCRIPTIONS" source="descriptions" validate={validate}>
            <SimpleFormIterator>
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
              <RichTextInput fullWidth label="TEXT" source="text" variant="standard" validate={validate} />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="DISCOVERY MARKET" source="discoveryMarket" validate={validate}>
            <SimpleFormIterator>
              <BooleanInput fullWidth label="DISCOVERY SETTING PAGE MEMBER" source="discoverySetting.pageMember" validate={validate} />
              <BooleanInput fullWidth label="DISCOVERY SETTING PAGE SEO" source="discoverySetting.pageSEO" validate={validate} />
              <TextInput fullWidth label="PRICE" source="price" variant="standard" validate={validate} />
              <TextInput fullWidth label="SHOP LINK" source="shopLink" variant="standard" validate={validate} />
              <AutocompleteArrayInput fullWidth label="SHIPPING COUNTRY" source="shippingCountry" variant="standard" validate={validate} choices={countries} />
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput fullWidth label="AGE MAX" source="ageMax" variant="standard" />
          <NumberInput fullWidth label="AGE MIN" source="ageMax" variant="standard" />
          <NumberInput fullWidth label="APRX READING TIME" source="aprxReadingTime" variant="standard" />
          <NumberInput fullWidth label="AUDIOBOOK DURATION MIN" source="audiobookDurationMin" variant="standard" />
          <ArrayInput fullWidth label="AUTHOR TIPS" source="authorTips" validate={validate}>
            <SimpleFormIterator>
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate}/>
              <RichTextInput fullWidth label="TEXT" source="text" variant="standard" validate={validate} />
            </SimpleFormIterator>
          </ArrayInput>
          <ReferenceArrayInput source="contributors" reference="_author" perPage={1000}>
            <AutocompleteArrayInput fullWidth label="CONTRIBUTORS" source="name" variant="standard" />
          </ReferenceArrayInput>
          <ReferenceInput source="author" reference="_author" perPage={1000}>
            <AutocompleteInput fullWidth label="AUTHOR" source="name" variant="standard" validate={validate} />
          </ReferenceInput>
          <ReferenceInput source="publisher" reference="_publisher" perPage={1000}>
            <AutocompleteInput fullWidth label="PUBLISHER" source="name" variant="standard" validate={validate} />
          </ReferenceInput>
          <TextInput fullWidth label="ISBN" source="isbn" variant="standard" validate={validate} />
          <AutocompleteArrayInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
          <AutocompleteInput fullWidth label="REC TYPE" source="recType" variant="standard" choices={recTypes} validate={validate} />
          <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
        </TabbedForm.Tab>

        <TabbedForm.Tab label="book files">
          <AutocompleteArrayInput
            fullWidth
            label="RECORDINGS LIST"
            source="recordingsList"
            variant="standard"
            createItemLabel="%{item}"
            choices={recordings}
            matchSuggestion={() => true}
            onCreate={(i: any) => {
              const id = i;
              const name = i;
              recordings.push({ id, name });
              setRecordings(recordings);
              return { id, name };
            }}
          />
          <AutocompleteArrayInput
            fullWidth
            label="SAMPLE RECORDINGS LIST"
            source="sampleRecordingList"
            variant="standard"
            createItemLabel="%{item}"
            choices={sampleRecordingList}
            matchSuggestion={() => true}
            onCreate={(i: any) => {
              const id = i;
              const name = i;
              sampleRecordingList.push({ id, name });
              setSampleRecordingList(recordings);
              return { id, name };
            }}
          />
          <ArrayInput fullWidth label="SAMPLE RECORDINGS LIST LANG" source="sampleRecordingListLang" validate={validate}>
            <SimpleFormIterator>
              <TextInput fullWidth label="Recording ID" source="recordingID" variant="standard" validate={validate} />
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate}/>
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="book pages">
          <ImageInput fullWidth label="COVER (3D) single" source="coverImg" accept="image/*" validate={validate}>
            <ImagePreviewField source="src" />
          </ImageInput>
          <ArrayInput fullWidth label="COVER (3D)" source="coverImg3d" validate={validate}>
            <SimpleFormIterator>
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
              <ImageInput fullWidth source="img" label="IMAGE" accept="image/*" validate={validate}>
                <ImagePreviewField source="src" label="lang" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="COVER Img Ebook (vertical)" source="coverImgEbook" validate={validate}>
            <SimpleFormIterator>
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate}/>
              <ImageInput fullWidth source="img" label="IMAGE" accept="image/*" validate={validate}>
                <ImagePreviewField source="src" label="lang" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="COVER Img Audiobook (square)" source="coverImgAudiobook" validate={validate}>
            <SimpleFormIterator>
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
              <ImageInput fullWidth source="img" label="IMAGE" accept="image/*" validate={validate}>
                <ImagePreviewField source="src" label="lang" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput fullWidth label="REC PAGE IMAGES" source="recPageImages" validate={validate}>
            <SimpleFormIterator>
              <NumberInput fullWidth label="PAGE NUMBER" source="pageNumber" variant="standard" validate={validate} />
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
              <ImageInput fullWidth source="img" label="IMAGE" accept="image/*" validate={validate}>
                <ImagePreviewField source="src" label="lang" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="book texts">
          <ArrayInput fullWidth label="REC PAGE LANGUAGES" source="recPageLanguages" validate={validate}>
            <SimpleFormIterator>
              <NumberInput fullWidth label="PAGE NUMBER" source="pageNumber" variant="standard" validate={validate} />
              <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
              <RichTextInput fullWidth label="TEXT" source="text" variant="standard" validate={validate} />
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="chapters">
          <ArrayInput fullWidth label="chapters" source="chapters">
            <SimpleFormIterator>
              <NumberInput fullWidth label="chapterId" source="chapterId" variant="standard" validate={validate} />

              <ArrayInput fullWidth label="names" source="names">
                <SimpleFormIterator>

                    <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} validate={validate} />
                    <TextInput fullWidth label="name" source="name" variant="standard" validate={validate} />

                </SimpleFormIterator>
              </ArrayInput>

            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  )
};
