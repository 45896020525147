
import { FC } from 'react';
import { ImageField } from 'react-admin';

export const ImagePreviewField: FC<any> = (props: any) => {
  const record = props?.record;
  const source = props?.source;

  if (typeof (record) == 'string') {
    return <ImageField record={{ [source]: record }} source={source} />;
  }
  return <ImageField record={record} source={source} />;
}
