import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, ShowButton, EditButton, useResourceContext, ExportButton, CreateButton, TopToolbar, TextInput, Datagrid, TextField, List, ReferenceField, ReferenceInput, AutocompleteInput, NumberField, NumberInput, UrlField } from 'react-admin';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';

const settings = {
	id: true,
	chipUid: true,
	nfcUrl: true,
	qrImgFileName: true,
	qrUrl: true,
	quantity: true,
	smartObjectBook: true,
	smartObjectBatch: true,
};

export const ManufacturingTagList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);

	const Actions: FC = () => (
		<TopToolbar>
			<FilterButton />
			<CreateButton size="medium" />
			<ExportButton size="medium" />
			<ColumnsButton columns={columns} />
		</TopToolbar>
	)

	const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

	if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
		filters.push(
			<TextInput label="ID" source="id" variant="standard" />
		);
	}
	if (columns?.chipUid) {
		components.push(
			<TextField label="CHIP UID" source="chipUid" key="chipUid" />
		);
		filters.push(
			<TextInput label="CHIP UID" source="chipUid" variant="standard" />
		);
	}
	if (columns?.nfcUrl) {
		components.push(
			<UrlField label="NTC URL" source="nfcUrl" key="nfcUrl" />
		);
		filters.push(
			<TextInput label="NTC URL" source="nfcUrl" variant="standard" />
		);
	}
	if (columns?.qrImgFileName) {
		components.push(
			<TextField label="QR IMG FILE NAME" source="qrImgFileName" key="qrImgFileName" />
		);
		filters.push(
			<TextInput label="QR IMG FILE NAME" source="qrImgFileName" variant="standard" />
		);
	}
	if (columns?.qrUrl) {
		components.push(
			<UrlField label="QR URL" source="qrUrl" key="qrUrl" />
		);
		filters.push(
			<TextInput label="QR URL" source="qrUrl" variant="standard" />
		);
	}
	if (columns?.quantity) {
		components.push(
			<NumberField label="QUANTITY" source="quantity" key="quantity" />
		);
		filters.push(
			<NumberInput label="QUANTITY" source="quantity" variant="standard" />
		);
	}
	if (columns?.smartObjectBatch) {
		components.push(
			<ReferenceField label="SMART OBJECT BATCH" source="smartObjectBatch" reference="_smart_object_batch" key="smartObjectBatch">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="SMART OBJECT BATCH" source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
				<AutocompleteInput label="SMART OBJECT BATCH" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
  if (columns?.smartObjectBook) {
    components.push(
      <ReferenceField label="SMART OBJECT" source="smartObjectBook" reference="_smart_object" key="smartObjectBook">
        <TextField source="nfcChipUid" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="SMART OBJECT" source="smartObjectBook" reference="_smart_object" perPage={1000}>
        <AutocompleteInput label="SMART OBJECT" optionText="nfcChipUid" variant="standard" source="type" />
      </ReferenceInput>
    );
  }

	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;

	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination}>
			<Datagrid size="medium">
				{components}
        <ShowButton size="medium" />
				<EditButton size="medium" />
			</Datagrid>
		</List>
	);
}
