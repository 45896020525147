export const languages = [
  { id: 'ab', name: 'Abkhazian' },
  { id: 'aa', name: 'Afar' },
  { id: 'af', name: 'Afrikaans' },
  { id: 'ak', name: 'Akan' },
  { id: 'sq', name: 'Albanian' },
  { id: 'am', name: 'Amharic' },
  { id: 'ar', name: 'Arabic' },
  { id: 'an', name: 'Aragonese' },
  { id: 'hy', name: 'Armenian' },
  { id: 'as', name: 'Assamese' },
  { id: 'av', name: 'Avaric' },
  { id: 'ae', name: 'Avestan' },
  { id: 'ay', name: 'Aymara' },
  { id: 'az', name: 'Azerbaijani' },
  { id: 'bm', name: 'Bambara' },
  { id: 'ba', name: 'Bashkir' },
  { id: 'eu', name: 'Basque' },
  { id: 'be', name: 'Belarusian' },
  { id: 'bn', name: 'Bengali' },
  { id: 'bh', name: 'Bihari languages' },
  { id: 'bi', name: 'Bislama' },
  { id: 'bs', name: 'Bosnian' },
  { id: 'br', name: 'Breton' },
  { id: 'bg', name: 'Bulgarian' },
  { id: 'my', name: 'Burmese' },
  { id: 'ca', name: 'Catalan, Valencian' },
  { id: 'km', name: 'Central Khmer' },
  { id: 'ch', name: 'Chamorro' },
  { id: 'ce', name: 'Chechen' },
  { id: 'ny', name: 'Chichewa, Chewa, Nyanja' },
  { id: 'zh', name: 'Chinese' },
  { id: 'cu', name: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
  { id: 'cv', name: 'Chuvash' },
  { id: 'kw', name: 'Cornish' },
  { id: 'co', name: 'Corsican' },
  { id: 'cr', name: 'Cree' },
  { id: 'hr', name: 'Croatian' },
  { id: 'cs', name: 'Czech' },
  { id: 'da', name: 'Danish' },
  { id: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
  { id: 'nl', name: 'Dutch, Flemish' },
  { id: 'dz', name: 'Dzongkha' },
  { id: 'en', name: 'English' },
  { id: 'eo', name: 'Esperanto' },
  { id: 'et', name: 'Estonian' },
  { id: 'ee', name: 'Ewe' },
  { id: 'fo', name: 'Faroese' },
  { id: 'fj', name: 'Fijian' },
  { id: 'fi', name: 'Finnish' },
  { id: 'fr', name: 'French' },
  { id: 'ff', name: 'Fulah' },
  { id: 'gd', name: 'Gaelic, Scottish Gaelic' },
  { id: 'gl', name: 'Galician' },
  { id: 'lg', name: 'Ganda' },
  { id: 'ka', name: 'Georgian' },
  { id: 'de', name: 'German' },
  { id: 'ki', name: 'Gikuyu, Kikuyu' },
  { id: 'el', name: 'Greek (Modern)' },
  { id: 'kl', name: 'Greenlandic, Kalaallisut' },
  { id: 'gn', name: 'Guarani' },
  { id: 'gu', name: 'Gujarati' },
  { id: 'ht', name: 'Haitian, Haitian Creole' },
  { id: 'ha', name: 'Hausa' },
  { id: 'he', name: 'Hebrew' },
  { id: 'hz', name: 'Herero' },
  { id: 'hi', name: 'Hindi' },
  { id: 'ho', name: 'Hiri Motu' },
  { id: 'hu', name: 'Hungarian' },
  { id: 'is', name: 'Icelandic' },
  { id: 'io', name: 'Ido' },
  { id: 'ig', name: 'Igbo' },
  { id: 'id', name: 'Indonesian' },
  { id: 'ia', name: 'Interlingua (International Auxiliary Language Association)' },
  { id: 'ie', name: 'Interlingue' },
  { id: 'iu', name: 'Inuktitut' },
  { id: 'ik', name: 'Inupiaq' },
  { id: 'ga', name: 'Irish' },
  { id: 'it', name: 'Italian' },
  { id: 'ja', name: 'Japanese' },
  { id: 'jv', name: 'Javanese' },
  { id: 'kn', name: 'Kannada' },
  { id: 'kr', name: 'Kanuri' },
  { id: 'ks', name: 'Kashmiri' },
  { id: 'kk', name: 'Kazakh' },
  { id: 'rw', name: 'Kinyarwanda' },
  { id: 'kv', name: 'Komi' },
  { id: 'kg', name: 'Kongo' },
  { id: 'ko', name: 'Korean' },
  { id: 'kj', name: 'Kwanyama, Kuanyama' },
  { id: 'ku', name: 'Kurdish' },
  { id: 'ky', name: 'Kyrgyz' },
  { id: 'lo', name: 'Lao' },
  { id: 'la', name: 'Latin' },
  { id: 'lv', name: 'Latvian' },
  { id: 'lb', name: 'Letzeburgesch, Luxembourgish' },
  { id: 'li', name: 'Limburgish, Limburgan, Limburger' },
  { id: 'ln', name: 'Lingala' },
  { id: 'lt', name: 'Lithuanian' },
  { id: 'lu', name: 'Luba-Katanga' },
  { id: 'mk', name: 'Macedonian' },
  { id: 'mg', name: 'Malagasy' },
  { id: 'ms', name: 'Malay' },
  { id: 'ml', name: 'Malayalam' },
  { id: 'mt', name: 'Maltese' },
  { id: 'gv', name: 'Manx' },
  { id: 'mi', name: 'Maori' },
  { id: 'mr', name: 'Marathi' },
  { id: 'mh', name: 'Marshallese' },
  { id: 'ro', name: 'Moldovan, Moldavian, Romanian' },
  { id: 'mn', name: 'Mongolian' },
  { id: 'na', name: 'Nauru' },
  { id: 'nv', name: 'Navajo, Navaho' },
  { id: 'nd', name: 'Northern Ndebele' },
  { id: 'ng', name: 'Ndonga' },
  { id: 'ne', name: 'Nepali' },
  { id: 'se', name: 'Northern Sami' },
  { id: 'no', name: 'Norwegian' },
  { id: 'nb', name: 'Norwegian Bokmål' },
  { id: 'nn', name: 'Norwegian Nynorsk' },
  { id: 'ii', name: 'Nuosu, Sichuan Yi' },
  { id: 'oc', name: 'Occitan (post 1500)' },
  { id: 'oj', name: 'Ojibwa' },
  { id: 'or', name: 'Oriya' },
  { id: 'om', name: 'Oromo' },
  { id: 'os', name: 'Ossetian, Ossetic' },
  { id: 'pi', name: 'Pali' },
  { id: 'pa', name: 'Panjabi, Punjabi' },
  { id: 'ps', name: 'Pashto, Pushto' },
  { id: 'fa', name: 'Persian' },
  { id: 'pl', name: 'Polish' },
  { id: 'pt', name: 'Portuguese' },
  { id: 'qu', name: 'Quechua' },
  { id: 'rm', name: 'Romansh' },
  { id: 'rn', name: 'Rundi' },
  { id: 'ru', name: 'Russian' },
  { id: 'sm', name: 'Samoan' },
  { id: 'sg', name: 'Sango' },
  { id: 'sa', name: 'Sanskrit' },
  { id: 'sc', name: 'Sardinian' },
  { id: 'sr', name: 'Serbian' },
  { id: 'sn', name: 'Shona' },
  { id: 'sd', name: 'Sindhi' },
  { id: 'si', name: 'Sinhala, Sinhalese' },
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  { id: 'so', name: 'Somali' },
  { id: 'st', name: 'Sotho, Southern' },
  { id: 'nr', name: 'South Ndebele' },
  { id: 'es', name: 'Spanish, Castilian' },
  { id: 'su', name: 'Sundanese' },
  { id: 'sw', name: 'Swahili' },
  { id: 'ss', name: 'Swati' },
  { id: 'sv', name: 'Swedish' },
  { id: 'tl', name: 'Tagalog' },
  { id: 'ty', name: 'Tahitian' },
  { id: 'tg', name: 'Tajik' },
  { id: 'ta', name: 'Tamil' },
  { id: 'tt', name: 'Tatar' },
  { id: 'te', name: 'Telugu' },
  { id: 'th', name: 'Thai' },
  { id: 'bo', name: 'Tibetan' },
  { id: 'ti', name: 'Tigrinya' },
  { id: 'to', name: 'Tonga (Tonga Islands)' },
  { id: 'ts', name: 'Tsonga' },
  { id: 'tn', name: 'Tswana' },
  { id: 'tr', name: 'Turkish' },
  { id: 'tk', name: 'Turkmen' },
  { id: 'tw', name: 'Twi' },
  { id: 'ug', name: 'Uighur, Uyghur' },
  { id: 'uk', name: 'Ukrainian' },
  { id: 'ur', name: 'Urdu' },
  { id: 'uz', name: 'Uzbek' },
  { id: 've', name: 'Venda' },
  { id: 'vi', name: 'Vietnamese' },
  { id: 'vo', name: 'Volap_k' },
  { id: 'wa', name: 'Walloon' },
  { id: 'cy', name: 'Welsh' },
  { id: 'fy', name: 'Western Frisian' },
  { id: 'wo', name: 'Wolof' },
  { id: 'xh', name: 'Xhosa' },
  { id: 'yi', name: 'Yiddish' },
  { id: 'yo', name: 'Yoruba' },
  { id: 'za', name: 'Zhuang, Chuang' },
  { id: 'zu', name: 'Zulu' }
].map(({ id }) => ({ id, name: id.toUpperCase() }));