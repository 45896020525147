import { getAuth } from 'firebase/auth';
import { FC } from 'react';
import { TopToolbar, ListButton, TextInput, Edit, SimpleForm, required, Toolbar, SaveButton, BooleanInput } from 'react-admin';

export const PublisherEdit: FC = () => {
  const auth = getAuth();
  const validate = required()

  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
  
  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }} toolbar={toolbar}>
        <TextInput disabled fullWidth label="ID" source="id" variant="standard" />
        <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Edit>
  );
}
