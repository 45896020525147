import { getAuth } from 'firebase/auth';
import { FC } from 'react';
import { TopToolbar, ListButton, TextInput, Create, SimpleForm, required, BooleanInput } from 'react-admin';

export const PublisherCreate: FC = () => {
  const auth = getAuth();
  const validate = required();

  const transform = (data: any) => {
    const createdBy = auth?.currentUser?.uid;
    const createdAt = new Date();
  
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, createdAt, createdBy, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const actions = <Actions />;

  return (
    <Create actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }}>
        <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Create>
  );
}
