import { defaultTheme } from 'react-admin';
import { createTheme, ThemeOptions } from '@mui/material/styles';

export const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    secondary: {
      main: '#121212',
    },
  },
};

export const lightTheme: ThemeOptions = defaultTheme;

export const useTheme = () => {
  const theme = localStorage.getItem('theme') || 'light';
  if (theme === 'light') {
    return createTheme(lightTheme);
  }
  if (theme === 'dark') {
    return createTheme(darkTheme);
  }
  return createTheme(defaultTheme);
};
