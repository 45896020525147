import { FirebaseDataProvider } from 'react-admin-firebase';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
} = process.env;

export const useDataProvider = () => {
  const apiKey = REACT_APP_FIREBASE_API_KEY;
  const messagingSenderId = REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
  const appId = REACT_APP_FIREBASE_APP_ID;
  const measurementId = REACT_APP_FIREBASE_MEASUREMENT_ID;
  const authDomain = REACT_APP_FIREBASE_AUTH_DOMAIN;
  const projectId = REACT_APP_FIREBASE_PROJECT_ID;
  const storageBucket = REACT_APP_FIREBASE_STORAGE_BUCKET;

  const useFileNamesInStorage = true; 
  const options = { apiKey, messagingSenderId, appId, measurementId, authDomain, projectId, storageBucket };
  const logging = false;
  const lazyLoading =  { enabled: true };
  const disableMeta = true;

  const provider = FirebaseDataProvider(options, { logging, useFileNamesInStorage, lazyLoading, disableMeta });
  return {
    ...provider,
    getList: async (resource: any, params: any) => {
      const providerResult = await provider.getList(resource, params);

      const data = providerResult?.data.map((i) => {
        if (['author', 'publisher', '_smart_object_book', '_smart_object_batch', '_smart_object'].includes(resource)) {
          i.isDeleted = i?.isDeleted || false
        }
        return i
      });
      const total = providerResult?.total;
      return { data, total };
    },
    getManyReference: async (resource: any, params: any) => {
      params.filter[params.target] = params.id;
      const providerResult = await provider.getList(resource, params);

      const data = providerResult?.data;
      const total = providerResult?.total;
      return { data, total };
    },
  }
}
