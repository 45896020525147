import { getAuth } from 'firebase/auth';
import { FC, useState } from 'react';
import { required, TopToolbar, ListButton, Create, SimpleForm, AutocompleteInput, TextInput, BooleanInput, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export const SmartObjectCreate: FC = () => {
  const [type, setType] = useState<any>([]);
  const auth = getAuth();
  const validate = required();

  const transform = (data: any) => {
    const createdBy = auth?.currentUser?.uid;
    const createdAt = new Date();
  
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, createdAt, createdBy, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const actions = <Actions />;

  return (
    <Create actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }}>
        <AutocompleteInput
          fullWidth
          label="TYPE"
          source="type"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={type}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            type.push({ id, name });
            setType(type);
            return { id, name };
          }}
        />
        <TextInput fullWidth label="NFC CHIP UID" source="nfcChipUid" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="NFC IS BANNED" source="nfcIsBanned" validate={validate} />
        <TextInput fullWidth label="NFC SECRET" source="nfcSecret" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="QR IS BANNED" source="qrIsBanned" validate={validate}/>
        <BooleanInput fullWidth label="QR IS STATIC" source="qrIsStatic" validate={validate}/>
        <TextInput fullWidth label="QR SECRET" source="qrSecret" variant="standard" validate={validate} />
        <ReferenceInput source="scannedByUser" reference="users" perPage={1000}>
          <AutocompleteInput fullWidth label="SCANNED BY" variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        <ReferenceInput source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
          <AutocompleteInput fullWidth label="SMART OBJECT BATCH" variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        <ReferenceInput source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
          <AutocompleteInput fullWidth label="SMART OBJECT BOOK" variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        <ReferenceArrayInput source="recordings" reference="_recordings" perPage={1000}>
          <AutocompleteArrayInput fullWidth label="RECORDINGS" optionText="id" variant="standard" validate={validate} />
			  </ReferenceArrayInput>
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" validate={validate} />
      </SimpleForm>
    </Create>
  );
}
