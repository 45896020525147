import { getAuth } from 'firebase/auth';
import { FC } from 'react';
import { required, SaveButton, Toolbar, TopToolbar, ListButton, TextInput, Edit, SimpleForm, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin';

export const ManufacturingTagEdit: FC = () => {
  const validate = required();
  const auth = getAuth();

  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
  
  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }} toolbar={toolbar}>
        <TextInput disabled fullWidth label="ID" source="id" variant="standard" />
        <TextInput disabled fullWidth label="CHIP UID" source="chipUid" variant="standard" />
        <TextInput fullWidth label="NTC URL" source="nfcUrl" variant="standard" validate={validate} />
        <TextInput disabled fullWidth label="QR IMG FILE NAME" source="qrImgFileName" variant="standard" />
        <TextInput fullWidth label="QR URL" source="qrUrl" variant="standard" validate={validate} />
        <NumberInput fullWidth label="QUANTITY URL" source="quantity" variant="standard" validate={validate} />
        <ReferenceInput source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
          <AutocompleteInput disabled fullWidth label="SMART OBJECT BATCH" variant="standard" source="name" />
        </ReferenceInput>
        <ReferenceInput source="smartObjectBook" reference="_smart_object" perPage={1000}>
          <AutocompleteInput disabled fullWidth label="SMART OBJECT" optionText="nfcChipUid" variant="standard" source="type" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
