import { getAuth } from 'firebase/auth';
import { RichTextInput } from 'ra-input-rich-text';
import { FC } from 'react';
import { TopToolbar, ListButton, TextInput, Create, SimpleForm, required, BooleanInput, ArrayInput, SimpleFormIterator, AutocompleteInput } from 'react-admin';
import { languages } from '../../configs/languages';

export const AuthorCreate: FC = () => {
  const auth = getAuth();
  const validate = required();

  const transform = (data: any) => {
    const createdBy = auth?.currentUser?.uid;
    const createdAt = new Date();
  
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, createdAt, createdBy, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const actions = <Actions />;

  return (
    <Create actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }}>
        <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
        <ArrayInput fullWidth label="NAMES" source="names">
          <SimpleFormIterator>
            <AutocompleteInput fullWidth label="LANG" source="lang" variant="standard" choices={languages} />
            <RichTextInput fullWidth label="NAME" source="name" variant="standard" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Create>
  );
}
