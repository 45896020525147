import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, ChipField, EditButton, useResourceContext, ExportButton, CreateButton, TopToolbar, TextInput, Datagrid, TextField, List, DateField, BooleanField, DateInput, BooleanInput, ReferenceField, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';
import { SoftDeleteManyButton } from '../../buttons/soft-delete-many/soft-delete-many-button';
import { SoftDeleteButton } from '../../buttons/soft-delete/soft-delete-button';

const settings = {
	id: true,
	batch: true,
	smartObjectBatch: true,
	nfcChipUid: true,
	nfcIsBanned: true,
	nfcSecret: true,
	qrIsBanned: true,
	qrIsStatic: true,
	qrSecret: true,
	scannedByUser: true,
	smartObjectBook: true,
	type: true,
	createdAt: false,
	createdBy: false,
	editedAt: false,
	editedBy: false,
	deletedAt: false,
	deletedBy: false,
	isDeleted: true,
};

export const SmartObjectList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);

	const BulkActionButtons: FC = () => <SoftDeleteManyButton />;

	const Actions: FC = () => (
		<TopToolbar>
			<FilterButton />
			<CreateButton size="medium" />
			<ExportButton size="medium" />
			<ColumnsButton columns={columns} />
		</TopToolbar>
	)

	const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

	if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
		filters.push(
			<TextInput label="ID" source="id" variant="standard" />
		);
	}
	if (columns?.type) {
		components.push(
			<ChipField label="TYPE" source="type" key="type" />
		);
		filters.push(
			<TextInput label="TYPE" source="type" variant="standard" />
		);
	}
	if (columns?.nfcChipUid) {
		components.push(
			<TextField label="NFC CHIP UID" source="nfcChipUid" key="nfcChipUid" />
		);
		filters.push(
			<TextInput label="NFC CHIP UID" source="nfcChipUid" variant="standard" />
		);
	}
  if (columns?.nfcIsBanned) {
		components.push(
			<BooleanField label="NFC IS BANNED" source="nfcIsBanned" key="nfcIsBanned" /> 
		);
		filters.push(
			<BooleanInput label="NFC IS BANNED" source="nfcIsBanned"/>
		);
	}
	if (columns?.nfcSecret) {
		components.push(
			<TextField label="NFC SECRET" source="nfcSecret" key="nfcSecret" />
		);
		filters.push(
			<TextInput label="NFC SECRET" source="nfcSecret" variant="standard" />
		);
	}
  if (columns?.qrIsBanned) {
		components.push(
			<BooleanField label="QR IS BANNED" source="qrIsBanned" key="qrIsBanned" /> 
		);
		filters.push(
			<BooleanInput label="QR IS BANNED" source="qrIsBanned"/>
		);
	}
  if (columns?.qrIsStatic) {
		components.push(
			<BooleanField label="QR IS STATIC" source="qrIsStatic" key="qrIsStatic" /> 
		);
		filters.push(
			<BooleanInput label="QR IS STATIC" source="qrIsStatic"/>
		);
	}
	if (columns?.qrSecret) {
		components.push(
			<TextField label="QR SECRET" source="qrSecret" key="qrSecret" />
		);
		filters.push(
			<TextInput label="QR SECRET" source="qrSecret" variant="standard" />
		);
	}
	if (columns?.scannedByUser) {
		components.push(
			<ReferenceField label="SCANNED BY" source="scannedByUser" key="scannedByUser" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="SCANNED BY" source="scannedByUser" reference="users" perPage={1000}>
				<AutocompleteInput label="SCANNED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.smartObjectBatch) {
		components.push(
			<ReferenceField label="SMART OBJECT BATCH" source="smartObjectBatch" reference="_smart_object_batch" key="smartObjectBatch">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="SMART OBJECT BATCH" source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
				<AutocompleteInput label="SMART OBJECT BATCH" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
  if (columns?.smartObjectBook) {
    components.push(
      <ReferenceField label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" key="smartObjectBook">
        <TextField source="name" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
        <AutocompleteInput label="SMART OBJECT BOOK" variant="standard" source="name" />
      </ReferenceInput>
    );
  }
	if (columns?.createdAt) {
		components.push(
			<DateField showTime label="CREATED AT" source="createdAt" key="createdAt" />
		);
		filters.push(
			<DateInput label="CREATED AT" source="createdAt" variant="standard" />
		);
	}
	if (columns?.createdBy) {
		components.push(
			<ReferenceField label="CREATED BY" source="createdBy" key="createdBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="CREATED BY" source="createdBy" reference="users" perPage={1000}>
				<AutocompleteInput label="CREATED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.editedAt) {
		components.push(
			<DateField showTime label="EDITED AT" source="editedAt" key="editedAt" />
		);
		filters.push(
			<DateInput label="EDITED AT" source="editedAt" variant="standard" />
		);
	}
	if (columns?.editedBy) {
		components.push(
			<ReferenceField label="EDITED BY" source="editedBy" key="editedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="EDITED BY" source="editedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="EDITED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.deletedAt) {
		components.push(
			<DateField showTime label="DELETED AT" source="deletedAt" key="deletedAt" /> 
		);
		filters.push(
			<DateInput label="DELETED AT" source="deletedAt" variant="standard" />
		);
	}
	if (columns?.deletedBy) {
		components.push(
			<ReferenceField label="DELETED BY" source="deletedBy" key="deletedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="DELETED BY" source="deletedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="DELETED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.isDeleted) {
		components.push(
			<BooleanField label="IS DELETED" source="isDeleted" key="isDeleted" /> 
		);
		filters.push(
			<BooleanInput label="IS DELETED" source="isDeleted"/>
		);
	}

	const bulkActionButtons = <BulkActionButtons />;
	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;

	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination}>
			<Datagrid size="medium" bulkActionButtons={bulkActionButtons}>
				{components}
				<EditButton size="medium" />
				<SoftDeleteButton />
			</Datagrid>
		</List>
	);
}
