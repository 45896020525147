import { FC, useState } from 'react';
import { Tooltip, Box, CircularProgress, Divider, Card, CardHeader, CardContent, TextField, CardActions, Typography, Button, IconButton } from '@mui/material';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { ThemeProvider } from '@mui/material/styles';
import { Facebook, Google, Twitter } from '@mui/icons-material';
import { useNotify, useLogin } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Logo } from '../logo/logo';
import { useTheme } from '../../providers/theme/theme-provider';

interface Values {
  email: string;
  password: string;
}

const validationSchema = object().shape({
  email: string()
    .email('Email is not available.')
    .required('Email is required'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});

const initialValues: Values = {
  email: '',
  password: '',
};

const LoginPage: FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const notify = useNotify();
  const login = useLogin();
  const theme = useTheme();

  const onSubmit = (values: Values) => {
    const username = values?.email;
    const password = values?.password;
    setLoading(true);
    login({ username, password })
      .then(() => {
        setLoading(false);
      
        const type = 'success';
        notify('Welcome to iKs Book Admin', { type });
      })
      .catch((err: Error) => {
        setLoading(false);

        const type = 'error';
        notify(err?.message || 'ra.auth.sign_in_error', { type });
      });
  }

  const onClickFacebook = () => {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        const type = 'success';
        notify('Welcome to iKs Book Admin', { type });
        navigate('/');
      })
      .catch((err) => {
        const type = 'error';
        notify(err?.message || 'ra.auth.sign_in_error', { type });
      })
  }

  const onClickGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        const type = 'success';
        notify('Welcome to iKs Book Admin', { type });
        navigate('/');
      })
      .catch((err) => {
        const type = 'error';
        notify(err?.message || 'ra.auth.sign_in_error', { type });
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="background.default" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}>
        <Card sx={{
          marginBottom: '16px',
        }}>

          <CardHeader
            sx={{
              padding: '4px 8px',
              bgcolor: 'secondary.main',
              color: 'secondary.contrastText',
            }}
            avatar={
              <IconButton color="inherit">
                <Logo />
              </IconButton>
            }
          />

          <CardContent sx={{
            width: '350px',
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <div>
                <Typography variant="h5">SIGN IN</Typography>
                <Typography variant="subtitle2">Better time stories</Typography>
              </div>

              <div>
                <IconButton color="primary" onClick={onClickFacebook}>
                  <Facebook />
                </IconButton>

                <IconButton color="primary" onClick={onClickGoogle}>
                  <Google />
                </IconButton>

                <IconButton color="primary" disabled>
                  <Twitter />
                </IconButton>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} noValidate>
                  <TextField
                    fullWidth
                    error={!!props.errors.email && !!props.touched.email}
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={props.touched.email && props.errors.email}
                    margin="normal"
                    variant="standard"
                    label="Email"
                    name="email"
                  />
                  <TextField
                    fullWidth
                    error={!!props.errors.password && !!props.touched.password}
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={props.touched.password && props.errors.password}
                    margin="normal"
                    variant="standard"
                    label="Password"
                    name="password"
                    type="password"
                  />
                  <div style={{
                      marginTop: '16px'
                  }}>
                    <Button variant="contained" type="submit" color="primary" disabled={loading}>
                      {loading && (
                        <CircularProgress
                          sx={{
                            marginRight: '10px',
                          }}
                          size={25}
                          thickness={2}
                        />
                      )}
                      Submit
                    </Button>
                    <Button disabled color="primary" sx={{
                      marginLeft: '10px',
                    }}>Forgot Password?</Button>
                  </div>
                </form>
              )}
            </Formik>
          </CardContent>

          <Divider />

          <CardActions sx={{
            justifyContent: 'space-between',
            padding: '8px 16px',
          }}>
            <Typography variant="overline">
              Don't have an account?
            </Typography>
            <Tooltip title="Connect with Administrator">
              <Button color="primary" href = "mailto: support@iksbook.com">Sign Up</Button>
            </Tooltip>
          </CardActions>
    
        </Card>
        <Typography
          variant="caption"
          display="block"
          color="textSecondary"
        >
          Copyright © 2021 iKs Book Admin
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export const loginPage: FC = () => <LoginPage />;

