import { getAuth } from 'firebase/auth';
import { FC } from 'react';
import { required, TopToolbar, ListButton, Create, SimpleForm, AutocompleteInput, TextInput, ReferenceInput, NumberInput } from 'react-admin';

export const ManufacturingTagCreate: FC = () => {
  const auth = getAuth();
  const validate = required();

  const transform = (data: any) => {
    const createdBy = auth?.currentUser?.uid;
    const createdAt = new Date();
  
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, createdAt, createdBy, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const actions = <Actions />;

  return (
    <Create actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }}>
        <TextInput fullWidth label="CHIP UID" source="chipUid" variant="standard" validate={validate} />
        <TextInput fullWidth label="NTC URL" source="nfcUrl" variant="standard" validate={validate} />
        <TextInput fullWidth label="QR IMG FILE NAME" source="qrImgFileName" variant="standard" validate={validate} />
        <TextInput fullWidth label="QR URL" source="qrUrl" variant="standard" validate={validate} />
        <NumberInput fullWidth label="QUANTITY URL" source="quantity" variant="standard" validate={validate} />
        <ReferenceInput source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
          <AutocompleteInput fullWidth label="SMART OBJECT BATCH" variant="standard" source="name" validate={validate}/>
        </ReferenceInput>
        <ReferenceInput source="smartObjectBook" reference="_smart_object" perPage={1000}>
          <AutocompleteInput fullWidth label="SMART OBJECT" optionText="nfcChipUid" variant="standard" source="type" validate={validate} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
