import { FC, useState, useCallback } from 'react';
import { Menu, MenuItem, FormControlLabel, Checkbox, Button } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useStore, useResourceContext } from 'react-admin';

export const ColumnsButton: FC<any> = (props) => {
  const resource = useResourceContext(props);

  const [columns, setColumns] = useStore<any>(resource, props.columns);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClick = useCallback((ev: any) => {
    setAnchorEl(ev.currentTarget);
  }, [setAnchorEl]);

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl]);
  
  const onChange = useCallback((ev: any) => {
    const i = ev.target.name;
    if (columns[i]) {
      setColumns({ ...columns, [i]: false });
    } else {
      setColumns({ ...columns, [i]: true });
    }
  }, [columns, setColumns]);

  return (
    <>
      <Button
        aria-controls="columns-button-menu"
        aria-haspopup="true" 
        startIcon={<Settings />}
        onClick={onClick}
      >
        Columns
      </Button>
      <Menu
        id="columns-button-menu"
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
      >
        {Object.keys(columns).map((name) => (
          <MenuItem key={name}>
            <FormControlLabel
              control={<Checkbox checked={columns[name]} onChange={onChange} inputProps={{ name }} />}
              label={name.replace( /([A-Z.])/g, ' $1').replace(/\./g, '').toUpperCase()}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
