import { getAuth } from 'firebase/auth';
import { FC, useState } from 'react';
import { required, useEditController, SaveButton, Toolbar, TopToolbar, ListButton, TextInput, Edit, SimpleForm, BooleanInput, ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export const SmartObjectEdit: FC = () => {
  const [type, setType] = useState<any>([]);
  const { record } = useEditController();
  const validate = required();
  const auth = getAuth();

  if (record?.type && !type.length) {
    const id = record?.type;
    const name = record?.type;
    type.push({ id, name });
    setType(type);
  }

  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
  
  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }} toolbar={toolbar}>
        <TextInput disabled fullWidth label="ID" source="id" variant="standard" />
        <AutocompleteInput
          fullWidth
          label="TYPE"
          source="type"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={type}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            type.push({ id, name });
            setType(type);
            return { id, name };
          }}
        />
        <TextInput disabled fullWidth label="NFC CHIP UID" source="nfcChipUid" variant="standard" />
        <BooleanInput fullWidth label="NFC IS BANNED" source="nfcIsBanned" validate={validate} />
        <TextInput disabled fullWidth label="NFC SECRET" source="nfcSecret" variant="standard" />
        <BooleanInput fullWidth label="QR IS BANNED" source="qrIsBanned" validate={validate}/>
        <BooleanInput fullWidth label="QR IS STATIC" source="qrIsStatic" validate={validate}/>
        <TextInput disabled fullWidth label="QR SECRET" source="qrSecret" variant="standard" />
        <ReferenceInput source="scannedByUser" reference="users" perPage={1000}>
          <AutocompleteInput fullWidth label="SCANNED BY" variant="standard" source="name" />
        </ReferenceInput>
        <ReferenceInput source="smartObjectBatch" reference="_smart_object_batch" perPage={1000}>
          <AutocompleteInput disabled fullWidth label="SMART OBJECT BATCH" variant="standard" source="name" />
        </ReferenceInput>
        <ReferenceInput source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
          <AutocompleteInput disabled fullWidth label="SMART OBJECT BOOK" variant="standard" source="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="recordings" reference="_recordings" perPage={1000}>
          <AutocompleteArrayInput fullWidth label="RECORDINGS" optionText="id" variant="standard" />
			  </ReferenceArrayInput>
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Edit>
  );
}
