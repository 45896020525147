import { useCallback, FC, memo, useState } from 'react';
import { TablePagination, LabelDisplayedRowsArgs, CircularProgress } from '@mui/material';
import { useTranslate, useListPaginationContext, sanitizeListRestProps, PaginationActions, PaginationLimit } from 'react-admin';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const Pagination: FC<any> = memo(props => {
  const listPaginationContext = useListPaginationContext(props);

  const setPage = listPaginationContext.setPage;
  const perPage = listPaginationContext.perPage;
  const resource = listPaginationContext.resource;
  const setPerPage = listPaginationContext.setPerPage;
  const hasNextPage = listPaginationContext.hasNextPage;
  const isLoading = listPaginationContext.isLoading;

  const rowsPerPageOptions = props.rowsPerPageOptions || [5, 10, 25];
  const limit = props.limit || <PaginationLimit />;
  const actions = props.actions;

  const translate = useTranslate();
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const sort = { field: 'id', order: 'DESC' };
  const filter = props.context.filterValues
  const pagination = { page: 1, perPage: 1 };
  const params ={ filter, sort, pagination };

  const auth = getAuth();
  const functions = getFunctions(auth?.app, 'europe-west1');

  httpsCallable<any, any>(functions, 'count')({ resource, params }).then((data) => {
    const total = data?.data?.total || 1
    setTotal(total)
    setTotalPages(Math.ceil(total / perPage));
  });

  const onPageChange = useCallback((ev: any, page: number) => {
    ev && ev.stopPropagation();
    
    const pages = totalPages || 0;
    if (page < 0 || page > pages - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', { page: page + 1 })
      );
    }
    setPage(page + 1);
  }, [totalPages, setPage, translate]);

  const onRowsPerPageChange = useCallback((ev: any) => {
    setPerPage(ev.target.value);
  }, [setPerPage]);

  const labelDisplayedRows = useCallback((labelDisplayedRowsArgs: LabelDisplayedRowsArgs) => {
    const { from, to, count } = labelDisplayedRowsArgs
    if (count === -1 && hasNextPage) {
      return translate('ra.navigation.partial_page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        _: `%{from}-%{to} of more than %{to}`,
      });
    } else {
      return translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count === -1 ? to : count,
        _: `%{from}-%{to} of %{count === -1 ? to : count}`,
      });
    }
  }, [translate, hasNextPage]);

  const getItemAriaLabel = useCallback((type: 'next' | 'previous' | 'first' | 'last') => {
    return translate(`ra.navigation.${type}`, { _: `Go to ${type} page` });
  }, [translate]);

  if (isLoading || !total) {
    return (
      <div style={{
        position: 'relative',
        padding: '10px',
        display: 'flex',
        justifyContent: 'right',
      }}>
        <CircularProgress />
      </div>
    );
  }

  if (total === 0 || listPaginationContext.page < 1 || (total != null && listPaginationContext.page > (totalPages || 0))) {
    return limit;
  }

  const ActionsComponent = actions
    ? actions
    : !isLoading && total != null
    ? PaginationActions
    : undefined;

  const count = total == null ? -1 : total;
  const page = listPaginationContext.page - 1;
  const disabled = !hasNextPage;
  const component = 'span';
  const nextIconButtonProps = { disabled };
  const labelRowsPerPage = translate('ra.navigation.page_rows_per_page');

  return (
      <TablePagination
        page={page}
        count={count}
        component={component}
        rowsPerPage={perPage}
        onPageChange={onPageChange}
        ActionsComponent={ActionsComponent}
        labelRowsPerPage={labelRowsPerPage}
        getItemAriaLabel={getItemAriaLabel}
        rowsPerPageOptions={rowsPerPageOptions}
        labelDisplayedRows={labelDisplayedRows}
        onRowsPerPageChange={onRowsPerPageChange}
        nextIconButtonProps={nextIconButtonProps}
        {...sanitizeListRestProps(props)}
      />
  );
});
