import { getAuth } from 'firebase/auth';
import { FC } from 'react';
import { TopToolbar, BooleanInput, ListButton, TextInput, Edit, SimpleForm, required, Toolbar, SaveButton, AutocompleteInput, ImageInput, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput, FileInput, FileField } from 'react-admin';
import { languages } from '../../configs/languages';
import { ImagePreviewField } from '../../fields/image-preview/image-preview-field';

export const RecordingEdit: FC = () => {
  const auth = getAuth();
  const validate = required();
  
  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
  
  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }} toolbar={toolbar}>
        <TextInput disabled fullWidth label="ID" source="id" variant="standard" />

        <TextInput fullWidth label="System NAME" source="name" variant="standard" validate={validate} />
        <TextInput fullWidth label="Narrator NAME" source="narratorName" variant="standard" validate={validate} />
        
        <AutocompleteInput fullWidth label="LANGUAGE" source="lang" variant="standard" choices={languages} validate={validate} />
        
        <NumberInput fullWidth label="DURATION Ms" source="durationMs" variant="standard" validate={validate} />
        
        <ImageInput fullWidth label="NARRATOR AVATAR" source="narratorAvatar" accept="image/*" validate={validate}>
          <ImagePreviewField source="src" title="title" />
        </ImageInput>
        
        <ReferenceInput source="narratorUser" reference="users" perPage={1000}>
          <AutocompleteInput fullWidth label="NARRATOR USER" variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        
        <TextInput fullWidth label="NARRATOR VIDEO INTRO" source="narratorVideoIntro.src" variant="standard" validate={validate} />
        
        <FileInput fullWidth label="FULL AUDIO" source="full_audio" variant="standard" validate={validate}>
          <FileField source="src" title='title' />
        </FileInput>
        
        <FileInput fullWidth label="FULL AUDIO TRANSITION" source="full_transition_audio" variant="standard" validate={validate}>
          <FileField source="src" title='title' />
        </FileInput>
        
        <NumberInput fullWidth label="full audio intro offset" source="full_audio_intro_offset" variant="standard" validate={validate} />
        
        <NumberInput fullWidth label="full transition audio intro offset" source="full_transition_audio_intro_offset" variant="standard" validate={validate} />
        
        <ReferenceInput source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
          <AutocompleteInput fullWidth label="SMART OBJECT BOOK" variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        
        <ArrayInput fullWidth label="PAGES" source="pages" validate={validate}>
          <SimpleFormIterator>
            <NumberInput fullWidth label="DURATION" source="duration" variant="standard" validate={validate} />
            <NumberInput fullWidth label="PAGE NUMBER" source="pageNum" variant="standard" validate={validate} />
            <TextInput fullWidth label="LINK" source="src" variant="standard" validate={validate} />
          </SimpleFormIterator>
        </ArrayInput>
        
        
        <ArrayInput fullWidth label="PAGES transition" source="pages_transition" validate={validate}>
          <SimpleFormIterator>
            <NumberInput fullWidth label="DURATION" source="duration" variant="standard" validate={validate} />
            <NumberInput fullWidth label="PAGE NUMBER" source="pageNum" variant="standard" validate={validate} />
            <TextInput fullWidth label="LINK" source="src" variant="standard" validate={validate} />
          </SimpleFormIterator>
        </ArrayInput>
        
        
        <BooleanInput fullWidth label="Is Original Narration (recording allowed only by admin)" source="isOriginalNarration" validate={validate} />
      </SimpleForm>
    </Edit>
  );
}
