import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, useResourceContext, ChipField, ExportButton, TopToolbar, TextInput, Datagrid, TextField, List } from 'react-admin';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';

const settings = {
	id: true,
	name: true,
	email: true,
	narrator: true,
};

export const UserList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);

	const BulkActionButtons: FC = () => <></>;

	const Actions: FC = () => (
		<TopToolbar>
			<FilterButton />
			<ExportButton size="medium" />
			<ColumnsButton columns={columns}/>
		</TopToolbar>
	)

	const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

	if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
	}
	if (columns?.name) {
		components.push(
			<TextField label="NAME" source="name" key="name" />
		);
		filters.push(
			<TextInput label="NAME" source="name" variant="standard" />
		);
	}
	if (columns?.email) {
		components.push(
			<TextField label="EMAIL" source="email" key="email" />
		);
		filters.push(
			<TextInput label="EMAIL" source="email" variant="standard" />
		);
	}
	if (columns?.narrator) {
		components.push(
			<ChipField label="NARRATOR" source="narrator" key="narrator" />
		);
		filters.push(
			<TextInput label="NARRATOR" source="narrator" variant="standard" />
		);
	}


	const bulkActionButtons = <BulkActionButtons />;
	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;
	
	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination}>
			<Datagrid size="medium" bulkActionButtons={bulkActionButtons}>
				{components}
			</Datagrid>
		</List>
	);
}
