import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '../../providers/theme/theme-provider';

export const NotFound: FC = () => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor="background.default" color="text.primary" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}>
        <Typography variant="h5" sx={{
          fontSize: '32px',
        }}>
          404
        </Typography>
        <Typography variant="subtitle1">
          {'Sorry we couldn\'t find that page'}
        </Typography>
        <Typography variant="subtitle1">
          {'Maybe the page you are looking for has been removed, or you typed in the wrong URL'}
        </Typography>
        <Button href="/" color="primary">Home</Button>
      </Box>
    </ThemeProvider>
  )
}
