import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, DateField, DateInput, BooleanInput, BooleanField, EditButton, useResourceContext, ExportButton, CreateButton, TopToolbar, TextInput, Datagrid, TextField, List, FunctionField, ReferenceField, ChipField, ReferenceInput, AutocompleteInput, AutocompleteArrayInput } from 'react-admin';
import { Avatar, AvatarGroup, Chip } from '@mui/material';
import { ImageSearch } from '@mui/icons-material';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';
import { SoftDeleteManyButton } from '../../buttons/soft-delete-many/soft-delete-many-button';
import { SoftDeleteButton } from '../../buttons/soft-delete/soft-delete-button';
import { languages } from '../../configs/languages';

const settings = {
	id: false,
	name: true,
	recordingsList:false,
  coverImg: true,
  author: true,
	publisher: true,
  createdAt: false,
  createdBy: false,
  editedAt: false,
  editedBy: false,
  deletedAt: false,
  deletedBy: false,
  isDeleted: true,
  isbn: true,
	lang: true,
  recPages: false,
  recType: false,
};

export const SmartObjectBookList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);

	const BulkActionButtons: FC = () => <SoftDeleteManyButton />;

  const Actions: FC = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton size="medium" />
      <ExportButton size="medium" />
      <ColumnsButton columns={settings}/>
    </TopToolbar>
  );

  const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

  if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
		filters.push(
			<TextInput label="ID" source="id" variant="standard" />
		);
	}
	if (columns?.name) {
		components.push(
			<TextField label="NAME" source="name" key="name" />
		);
		filters.push(
			<TextInput label="NAME" source="name" variant="standard" />
		);
	}
  if (columns?.coverImg) {
    components.push(
      <FunctionField label="COVER" key="coverImg" render={
        (r: any) => r?.coverImg?.src
          ? <Avatar alt={r.name} src={r.coverImg.src} /> 
          : <Avatar alt={r.name}><ImageSearch /></Avatar>
      }/>
    )
  }
	if (columns?.createdAt) {
		components.push(
			<DateField showTime label="CREATED AT" source="createdAt" key="createdAt" />
		);
		filters.push(
			<DateInput label="CREATED AT" source="createdAt" variant="standard" />
		);
	}
	if (columns?.createdBy) {
		components.push(
			<ReferenceField label="CREATED BY" source="createdBy" key="createdBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput source="createdBy" reference="users" perPage={1000}>
				<AutocompleteInput label="CREATED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.editedAt) {
		components.push(
			<DateField showTime label="EDITED AT" source="editedAt" key="editedAt" />
		);
		filters.push(
			<DateInput label="EDITED AT" source="editedAt" variant="standard" />
		);
	}
	if (columns?.editedBy) {
		components.push(
			<ReferenceField label="EDITED BY" source="editedBy" key="editedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput source="editedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="EDITED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.deletedAt) {
		components.push(
			<DateField showTime label="DELETED AT" source="deletedAt" key="deletedAt" /> 
		);
		filters.push(
			<DateInput label="DELETED AT" source="deletedAt" variant="standard" />
		);
	}
	if (columns?.deletedBy) {
		components.push(
			<ReferenceField label="DELETED BY" source="deletedBy" key="deletedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput source="deletedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="DELETED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.isDeleted) {
		components.push(
			<BooleanField label="IS DELETED" source="isDeleted" key="isDeleted" /> 
		);
		filters.push(
			<BooleanInput label="IS DELETED" source="isDeleted"/>
		);
	}
  if (columns?.author) {
    components.push(
      <ReferenceField label="AUTHOR" source="author" reference="author" key="author">
        <TextField source="name" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="AUTHOR" source="author" reference="author" perPage={1000}>
        <AutocompleteInput variant="standard" source="name" />
      </ReferenceInput>
    );
  }
  if (columns?.publisher) {
    components.push(
      <ReferenceField label="PUBLISHER" source="publisher" reference="publisher" key="publisher">
        <TextField source="name" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="PUBLISHER" source="publisher" reference="publisher" perPage={1000}>
        <AutocompleteInput variant="standard" source="name" />
      </ReferenceInput>
    );
  }
  if (columns?.isbn) {
    components.push(
      <ChipField label="ISBN" source="isbn" key="isbn"/>
    );
    filters.push(
      <TextInput label="ISBN" source="isbn" variant="standard" />
    );
  }
	
	
	if (columns?.recordingsList) {
		components.push(
			<FunctionField label="recordingsList" key="recordingsList" render={
				(r: any) => r?.recordingsList ? r.recordingsList.map((i: string, index: number) => <Chip key={index} label={i?.toUpperCase()} />) : '-'
			} />
		);
		filters.push(
			<AutocompleteArrayInput label="recordingsList" source="recordingsList" variant="standard" />
		);
	}

	
	if (columns?.lang) {
		components.push(
			<FunctionField label="LANG" key="lang" render={
				(r: any) => r?.lang ? r.lang.map((i: string, index: number) => <Chip key={index} label={i?.toUpperCase()} />) : '-'
			} />
		);
		filters.push(
			<AutocompleteArrayInput label="LANG" source="lang" variant="standard" choices={languages} />
		);
	}

  if (columns?.recPages) {
    components.push(
      <FunctionField label="REC PAGES"  key="recPages" render={
        (r: any) => (
          <AvatarGroup max={3}>
            {r.recPages?.map(
              (i: any, key: number) => i?.img?.src
                ? <Avatar key={key} alt={i.text} src={i.img.src} />
                : <Avatar key={key} alt={i.text}><ImageSearch /></Avatar>
            )}
          </AvatarGroup>
        )
      }/>
    );
  }

  if (columns?.recType) {
    components.push(
      <ChipField label="REC TYPE" source="recType" key="recType" />
    );
  }

	const isDeleted = false;
	const filterDefaultValues = { isDeleted };

	const bulkActionButtons = <BulkActionButtons />;
	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;

	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination} filterDefaultValues={filterDefaultValues}>
			<Datagrid size="medium" bulkActionButtons={bulkActionButtons}>
				{components}
        <EditButton size="medium" />
        <SoftDeleteButton />
			</Datagrid>
		</List>
	);
}
