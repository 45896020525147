import { FC } from 'react';
import { useTheme } from 'react-admin';
import { IconButton, Tooltip } from '@mui/material';
import { WbIncandescentOutlined, WbIncandescent } from '@mui/icons-material';
import { ThemeOptions } from '@mui/material/styles';

interface Theme {
  light: ThemeOptions;
  dark: ThemeOptions;
}

export const ThemeButton: FC<Theme> = (props) => {
  const [theme, setTheme] = useTheme();
  const { light, dark } = props;

  const onClick = () => {
    setTheme(theme === 'dark' ? light : dark);
  }

  localStorage.setItem('theme', (theme as any) || 'light');

  return (
    <Tooltip title="Toggle Theme" enterDelay={300}>
      <IconButton color="inherit" onClick={onClick}>
        {theme === 'dark' ? <WbIncandescentOutlined /> : <WbIncandescent />}
      </IconButton>
    </Tooltip>
  )
}
