import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, useResourceContext, EditButton, ExportButton, CreateButton, TopToolbar, TextInput, Datagrid, TextField, List, DateField, DateInput, ReferenceInput, AutocompleteInput, ReferenceField, FunctionField } from 'react-admin';
import { Avatar, Chip } from '@mui/material';
import { ImageSearch } from '@mui/icons-material';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';
import { languages } from '../../configs/languages';
import { SoftDeleteButton } from '../../buttons/soft-delete/soft-delete-button';

const settings = {
	id: true,
	name: true,
	lang: true,
	narratorUser: true,
	narratorAvatar: true,
	narratorVideoIntro: true,
	smartObjectBook: true,
	createdAt: false,
	createdBy: false,
	editedAt: false,
	editedBy: false,
};

export const RecordingList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);


	const Actions: FC = () => (
		<TopToolbar>
			<FilterButton />
			<CreateButton size="medium" />
			<ExportButton size="medium" />
			<ColumnsButton columns={columns}/>
		</TopToolbar>
	)

	const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

	if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
		filters.push(
			<TextInput label="ID" source="id" variant="standard" />
		);
	}
	if (columns?.name) {
		components.push(
			<TextField label="NAME" source="name" key="name" />
		);
		filters.push(
			<TextInput label="NAME" source="name" variant="standard" />
		);
	}
	if (columns?.lang) {
		components.push(
			<FunctionField label="LANG" render={
				(r: any) => r?.lang ? <Chip label={r.lang.toUpperCase()} /> : '-'
			} />
		);
		filters.push(
			<AutocompleteInput label="LANG" source="lang" variant="standard" choices={languages} />
		);
	}
	if (columns?.narratorAvatar) {
    components.push(
      <FunctionField label="NARRATOR AVATAR" key="narratorAvatar" render={
        (r: any) => r?.narratorAvatar?.src
          ? <Avatar alt={r.id} src={r.narratorAvatar.src} /> 
          : <Avatar alt={r.id}><ImageSearch /></Avatar>
      }/>
    )
  }
	if (columns?.narratorUser) {
    components.push(
			<ReferenceField label="NARRATOR USER" source="narratorUser" reference="users" key="narratorUser">
				<TextField source="name" />
			</ReferenceField>
    )
		filters.push(
			<ReferenceInput source="narratorUser" reference="users" perPage={1000}>
				<AutocompleteInput label="NARRATOR USER" variant="standard" source="name" />
			</ReferenceInput>
		);
  }
	if (columns?.narratorVideoIntro) {
		components.push(
			<TextField label="NARRATOR VIDEO INTRO" key="narratorVideoIntro" source="narratorVideoIntro.src" />
		);
		filters.push(
			<TextInput label="NARRATOR VIDEO INTRO" source="narratorVideoIntro.src" variant="standard" />
		);
	}
	if (columns?.smartObjectBook) {
    components.push(
      <ReferenceField label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" key="smartObjectBook">
        <TextField source="name" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
        <AutocompleteInput label="SMART OBJECT BOOK" variant="standard" source="name" />
      </ReferenceInput>
    );
  }
	if (columns?.createdAt) {
		components.push(
			<DateField showTime label="CREATED AT" source="createdAt" key="createdAt" />
		);
		filters.push(
			<DateInput label="CREATED AT" source="createdAt" variant="standard" />
		);
	}
	if (columns?.createdBy) {
		components.push(
			<ReferenceField label="CREATED BY" source="createdBy" key="createdBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput source="createdBy" reference="users" perPage={1000}>
				<AutocompleteInput label="CREATED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.editedAt) {
		components.push(
			<DateField showTime label="EDITED AT" source="editedAt" key="editedAt" />
		);
		filters.push(
			<DateInput label="EDITED AT" source="editedAt" variant="standard" />
		);
	}
	if (columns?.editedBy) {
		components.push(
			<ReferenceField label="EDITED BY" source="editedBy" key="editedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput source="editedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="EDITED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}

	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;

	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination}>
			<Datagrid size="medium">
				{components}
				<EditButton size="medium" />
				<SoftDeleteButton />
			</Datagrid>
		</List>
	);
}