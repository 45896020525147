import { getAuth } from 'firebase/auth';
import { FC, useState } from 'react';
import { TopToolbar, ListButton, TextInput, Create, SimpleForm, required, AutocompleteInput, BooleanInput, NumberInput, ReferenceInput } from 'react-admin';

export const SmartObjectBatchCreate: FC = () => {
  const [smartObjectType, setSmartObjectType] = useState<any>([]);
  const [manufacturingStatus, setManufacturingStatus] = useState<any>([]);
  const auth = getAuth();
  const validate = required();

  const transform = (data: any) => {
    const createdBy = auth?.currentUser?.uid;
    const createdAt = new Date();
  
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, createdAt, createdBy, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const actions = <Actions />;

  return (
    <Create actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }}>
        <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
        <NumberInput fullWidth label="COUNT" source="count" variant="standard" validate={validate} />
        <TextInput fullWidth label="BATCH NAME" source="batchName" variant="standard" validate={validate} />
        <ReferenceInput source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
          <AutocompleteInput label="SMART OBJECT BOOK" fullWidth variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        <AutocompleteInput
          fullWidth
          label="SMART OBJECT TYPE"
          source="smartObjectType"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={smartObjectType}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            smartObjectType.push({ id, name });
            setSmartObjectType(smartObjectType);
            return { id, name };
          }}
        />
        <AutocompleteInput
          fullWidth
          label="MANUFACTURING STATUS"
          source="manufacturingStatus"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={manufacturingStatus}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            manufacturingStatus.push({ id, name });
            setManufacturingStatus(manufacturingStatus);
            return { id, name };
          }}
        />
        <BooleanInput fullWidth label="TYPE NFC" source="type.nfc" validate={validate} />
        <BooleanInput fullWidth label="TYPE QR" source="type.qr" validate={validate} />
        <NumberInput fullWidth label="URL VERSION" source="urlVersion" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="IS STATIC" source="isStatic" validate={validate} />
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Create>
  );
}
