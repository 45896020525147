import { FC } from 'react';
import { AppBar } from 'react-admin';
import { Typography, IconButton } from '@mui/material';
import { lightTheme, darkTheme } from '../../providers/theme/theme-provider';
import { Logo } from '../logo/logo';
import { ThemeButton } from '../../buttons/theme/theme-button';


export const appBar: FC = (props) => (
  <AppBar {...props}>
    <Typography
      sx={{
        flex: 1,
      }}
      variant="h6"
      color="inherit"
      id="react-admin-title"
    />

    <div style={{ flex: 1 }}>
      <IconButton color="inherit">
        <Logo />
      </IconButton>
    </div>

    <ThemeButton light={lightTheme} dark={darkTheme} />
  </AppBar>
);
