import { FirebaseAuthProvider } from 'react-admin-firebase';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
} = process.env;

export const useAuthProvider = () => {
  const apiKey = REACT_APP_FIREBASE_API_KEY;
  const messagingSenderId = REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
  const appId = REACT_APP_FIREBASE_APP_ID;
  const measurementId = REACT_APP_FIREBASE_MEASUREMENT_ID;
  const authDomain = REACT_APP_FIREBASE_AUTH_DOMAIN;
  const projectId = REACT_APP_FIREBASE_PROJECT_ID;
  const storageBucket = REACT_APP_FIREBASE_STORAGE_BUCKET;

  const options = { apiKey, messagingSenderId, appId, measurementId, authDomain, projectId, storageBucket };
  const logging = false;
  const lazyLoading =  { enabled: true };

  return FirebaseAuthProvider(options, { logging, lazyLoading });
}