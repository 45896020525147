import { FC, useState } from 'react';
import { useNotify, useRecordContext, useRefresh, useResourceContext, useUpdate } from 'react-admin';
import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getAuth } from 'firebase/auth';

export const SoftDeleteButton: FC<any> = (props) => {
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const refresh = useRefresh();
    const notify = useNotify();
    const auth = getAuth();

    const [update, { isLoading }] = useUpdate(resource);
    const [open, setOpen] = useState(false);

    const id = record?.id;
    const isDeleted = true;
    const deletedBy = auth?.currentUser?.uid;
    const deletedAt = new Date();
    const data = { isDeleted, deletedBy, deletedAt };

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const onSuccess = () => {
        const type = 'info';
        const message = 'ra.notification.deleted';
        const smart_count = 1;
        const messageArgs = { smart_count };
        notify(message, { type, messageArgs });
        refresh();
    }
    const onError = (err: any) => {
        const type = 'warning';
        const message = err?.message ? err?.message : err;
        const messageArgs = { _: message };
        notify(err?.message || 'ra.notification.http_error', { type, messageArgs });
        refresh();
    }

    const disabled = isLoading;
    const onClick = () => {
      update(resource, { id, data }, { onSuccess, onError });
      setOpen(false);
    };

    const startIcon = <Delete />;
    return (
      <>
        <Button startIcon={startIcon} disabled={record.isDeleted || disabled} onClick={handleOpen} color="error">
          Delete
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            DELETE PROJECT #{id}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button onClick={onClick}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </>
    );
};