import { getAuth } from 'firebase/auth';
import { FC, useState } from 'react';
import { SaveButton, Toolbar, TopToolbar, ListButton, TextInput, Edit, SimpleForm, required, NumberInput, ReferenceInput, AutocompleteInput, BooleanInput, useEditController } from 'react-admin';

export const SmartObjectBatchEdit: FC = () => {
  const [smartObjectType, setSmartObjectType] = useState<any>([]);
  const [manufacturingStatus, setManufacturingStatus] = useState<any>([]);
  const { record } = useEditController();
  const auth = getAuth();
  const validate = required();

  if (record?.smartObjectType && !smartObjectType.length) {
    const id = record?.recType;
    const name = record?.recType;
    smartObjectType.push({ id, name });
    setSmartObjectType(smartObjectType);
  }

  if (record?.manufacturingStatus && !manufacturingStatus.length) {
    const id = record?.recType;
    const name = record?.recType;
    manufacturingStatus.push({ id, name });
    setManufacturingStatus(manufacturingStatus);
  }

  const transform = (data: any) => {
    const editedBy = auth?.currentUser?.uid;
    const editedAt = new Date();
    return { ...data, editedAt, editedBy };
  };

  const Actions: FC = () => (
    <TopToolbar>
      <ListButton size="medium" />
    </TopToolbar>
  );

  const ToolBar: FC =() => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
  
  const actions = <Actions />;
  const toolbar = <ToolBar />;

  return (
    <Edit actions={actions} transform={transform}>
      <SimpleForm sx={{ width: '60%' }} toolbar={toolbar}>
        <TextInput disabled fullWidth label="ID" source="id" variant="standard" />
        <TextInput fullWidth label="NAME" source="name" variant="standard" validate={validate} />
        <NumberInput disabled fullWidth label="COUNT" source="count" variant="standard" validate={validate} />
        <TextInput fullWidth label="BATCH NAME" source="batchName" variant="standard" validate={validate} />
        <ReferenceInput source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
          <AutocompleteInput label="SMART OBJECT BOOK" fullWidth variant="standard" source="name" validate={validate} />
        </ReferenceInput>
        <AutocompleteInput
          fullWidth
          label="SMART OBJECT TYPE"
          source="smartObjectType"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={smartObjectType}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            smartObjectType.push({ id, name });
            setSmartObjectType(smartObjectType);
            return { id, name };
          }}
        />
        <AutocompleteInput
          fullWidth
          label="MANUFACTURING STATUS"
          source="manufacturingStatus"
          variant="standard"
          createItemLabel="%{item}"
          validate={validate}
          choices={manufacturingStatus}
          matchSuggestion={() => true}
          onCreate={(i: any) => {
            const id = i;
            const name = i;
            manufacturingStatus.push({ id, name });
            setManufacturingStatus(manufacturingStatus);
            return { id, name };
          }}
        />
        <BooleanInput fullWidth label="TYPE NFC" source="type.nfc" validate={validate} />
        <BooleanInput fullWidth label="TYPE QR" source="type.qr" validate={validate} />
        <NumberInput fullWidth label="URL VERSION" source="urlVersion" variant="standard" validate={validate} />
        <BooleanInput fullWidth label="IS STATIC" source="isStatic" validate={validate} />
        <BooleanInput fullWidth label="IS DELETED" source="isDeleted" />
      </SimpleForm>
    </Edit>
  );
}
