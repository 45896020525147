import { FC } from 'react';
import { useResourceContext, useListContext, useUpdateMany, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import { getAuth } from 'firebase/auth';

export const SoftDeleteManyButton: FC = (props) => {
  const resource = useResourceContext(props);

  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const refresh = useRefresh();
  const notify = useNotify();
  const auth = getAuth();
  
  const ids = selectedIds;
  const isDeleted = true;
  const deletedBy = auth?.currentUser?.uid;
  const deletedAt = new Date();
  const data = { isDeleted, deletedBy, deletedAt };

  const onSuccess = () => {
    const type = 'info';
    const message = 'ra.notification.deleted';
    const smart_count = selectedIds.length;
    const messageArgs = { smart_count  };
    notify(message, { type, messageArgs });
    unselectAll();
    refresh();
  }
  const onError = (err: any) => {
    const type = 'warning';
    const message = err?.message ? err?.message : err;
    const messageArgs = { _: message };
    notify(err?.message || 'ra.notification.http_error', { type, messageArgs });
    refresh();
  }

  const [updateMany, { isLoading }] = useUpdateMany(resource);

  const disabled = isLoading;
  const onClick = () => updateMany(resource, { ids, data }, { onSuccess, onError });

  const startIcon = <Delete />;
  return (
    <Button startIcon={startIcon} disabled={disabled} onClick={onClick} color="error">
      Delete
    </Button>
  );
};
