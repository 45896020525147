import { FC } from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { useDataProvider } from '../providers/data/data-provider';
import { useAuthProvider } from '../providers/auth/auth-provider';
import { useTheme } from '../providers/theme/theme-provider';

import { UserList } from '../resources/user/user-list';
import { UserIcon } from '../resources/user/user-icon';

import { AuthorList } from '../resources/author/author-list';
import { AuthorIcon } from '../resources/author/author-icon';
import { AuthorEdit } from '../resources/author/author-edit';
import { AuthorCreate } from '../resources/author/author-create';

import { PublisherList } from '../resources/publisher/publisher-list';
import { PublisherIcon } from '../resources/publisher/publisher-icon';
import { PublisherEdit } from '../resources/publisher/publisher-edit';
import { PublisherCreate } from '../resources/publisher/publisher-create';

import { RecordingList } from '../resources/recording/recording-list';
import { RecordingIcon } from '../resources/recording/recording-icon';
import { RecordingEdit } from '../resources/recording/recording-edit';
import { RecordingCreate } from '../resources/recording/recording-create';

import { ManufacturingTagList } from '../resources/manufacturing-tag/manufacturing-tag-list';
import { ManufacturingTagIcon } from '../resources/manufacturing-tag/manufacturing-tag-icon';
import { ManufacturingTagEdit } from '../resources/manufacturing-tag/manufacturing-tag-edit';
import { ManufacturingTagCreate } from '../resources/manufacturing-tag/manufacturing-tag-create';

import { SmartObjectList } from '../resources/smart-object/smart-object-list';
import { SmartObjectIcon } from '../resources/smart-object/smart-object-icon';
import { SmartObjectEdit } from '../resources/smart-object/smart-object-edit';
import { SmartObjectCreate } from '../resources/smart-object/smart-object-create';

import { SmartObjectBookList } from '../resources/smart-object-book/smart-object-book-list';
import { SmartObjectBookIcon } from '../resources/smart-object-book/smart-object-book-icon';
import { SmartObjectBookEdit } from '../resources/smart-object-book/smart-object-book-edit';
import { SmartObjectBookCreate } from '../resources/smart-object-book/smart-object-book-create';

import { SmartObjectBatchList } from '../resources/smart-object-batch/smart-object-batch-list';
import { SmartObjectBatchIcon } from '../resources/smart-object-batch/smart-object-batch-icon';
import { SmartObjectBatchEdit } from '../resources/smart-object-batch/smart-object-batch-edit';
import { SmartObjectBatchCreate } from '../resources/smart-object-batch/smart-object-batch-create';

import { NotFound } from './not-found/not-found';
import { Forbidden } from './forbidden/forbidden';
import { catchAll } from './catch-all/catch-all';
import { loginPage } from './login-page/login-page';
import { layout } from './layout/layout';

import './app.css';

export const App: FC = () => {
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const theme = useTheme();

  if (!dataProvider && !authProvider) {
    return (
      <ThemeProvider theme={theme}>
        <Box bgcolor="background.default" sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}>
          <CircularProgress size={89} />
          <div className="hidden">Loading...</div>
        </Box>
      </ThemeProvider>
    );
  }
  
  return (
    <Admin disableTelemetry theme={theme} catchAll={catchAll} loginPage={loginPage} layout={layout} authProvider={authProvider} dataProvider={dataProvider}>
      <CustomRoutes noLayout>
        <Route path="403" element={<Forbidden />} />
        <Route path="404" element={<NotFound />} />
      </CustomRoutes>
      <Resource options={{ label: 'Users' }} recordRepresentation="name" name="users" list={UserList} icon={UserIcon} />
      <Resource options={{ label: 'Authors' }} recordRepresentation="name" name="_author" list={AuthorList} icon={AuthorIcon} edit={AuthorEdit} create={AuthorCreate} />
      <Resource options={{ label: 'Publishers' }} recordRepresentation="name" name="_publisher" list={PublisherList} icon={PublisherIcon} edit={PublisherEdit} create={PublisherCreate} />
      <Resource options={{ label: 'Recordings' }} name="_recordings" list={RecordingList} icon={RecordingIcon} edit={RecordingEdit} create={RecordingCreate} />
      <Resource options={{ label: 'Smart Object Books' }} recordRepresentation="name" name="_smart_object_book" list={SmartObjectBookList} icon={SmartObjectBookIcon} edit={SmartObjectBookEdit} create={SmartObjectBookCreate} />
      <Resource options={{ label: 'Smart Object Batches' }} recordRepresentation="name" name="_smart_object_batch" list={SmartObjectBatchList} icon={SmartObjectBatchIcon} edit={SmartObjectBatchEdit} create={SmartObjectBatchCreate} />
      <Resource options={{ label: 'Smart Objects' }} recordRepresentation="name" name="_smart_object" list={SmartObjectList} icon={SmartObjectIcon} edit={SmartObjectEdit} create={SmartObjectCreate} />
      <Resource options={{ label: 'Manufacturing Tags' }} name="_manufacturing_tag" list={ManufacturingTagList} icon={ManufacturingTagIcon} edit={ManufacturingTagEdit} create={ManufacturingTagCreate}/>
    </Admin>
  );
}
