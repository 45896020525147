import { FC } from 'react';
import { usePickFilterContext, useListController, useStore, EditButton, useResourceContext, ExportButton, CreateButton, TopToolbar, TextInput, Datagrid, TextField, List, DateField, BooleanField, DateInput, BooleanInput, NumberInput, NumberField, ReferenceField, ReferenceInput, AutocompleteInput, ChipField } from 'react-admin';
import { Pagination } from '../../app/pagination/pagination';
import { FilterButton } from '../../buttons/filter/filter-button';
import { ColumnsButton } from '../../buttons/columns/columns-button';
import { SoftDeleteManyButton } from '../../buttons/soft-delete-many/soft-delete-many-button';
import { SoftDeleteButton } from '../../buttons/soft-delete/soft-delete-button';

const settings = {
	id: false,
	name: true,
  count: true,
  batchName: false,
  smartObjectBook: true,
  manufacturingStatus: true,
  smartObjectType: false,
  'type.nfc': false,
  'type.qr': false,
  urlVersion: false,
	createdAt: false,
	createdBy: false,
	editedAt: false,
	editedBy: false,
	deletedAt: false,
	deletedBy: false,
	isDeleted: true,
  isStatic: false,
};

export const SmartObjectBatchList: FC = (props) => {
	const listController = useListController(props);
	const filterContext = usePickFilterContext(listController);
	const resource = useResourceContext(props);
	const [columns] = useStore<any>(resource, settings);

	const BulkActionButtons: FC = () => <SoftDeleteManyButton />;

	const Actions: FC = () => (
		<TopToolbar>
			<FilterButton />
			<CreateButton size="medium" />
			<ExportButton size="medium" />
			<ColumnsButton columns={columns} />
		</TopToolbar>
	)

	const components: JSX.Element[] = [];
	const filters: JSX.Element[] = [];

	if (columns?.id) {
		components.push(
			<TextField label="ID" source="id" key="id" />
		);
		filters.push(
			<TextInput label="ID" source="id" variant="standard" />
		);
	}
	if (columns?.name) {
		components.push(
			<TextField label="NAME" source="name" key="name" />
		);
		filters.push(
			<TextInput label="NAME" source="name" variant="standard" />
		);
	}
  if (columns?.count) {
		components.push(
			<NumberField label="COUNT" source="count" key="count" />
		);
		filters.push(
			<NumberInput label="COUNT" source="count" variant="standard" />
		);
	}
  if (columns?.batchName) {
		components.push(
			<TextField label="BATCH NAME" source="batchName" key="batchName" />
		);
		filters.push(
			<TextInput label="BATCH NAME" source="batchName" variant="standard" />
		);
	}
  if (columns?.smartObjectBook) {
    components.push(
      <ReferenceField label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" key="smartObjectBook">
        <TextField source="name" />
      </ReferenceField>
    );
    filters.push(
      <ReferenceInput label="SMART OBJECT BOOK" source="smartObjectBook" reference="_smart_object_book" perPage={1000}>
        <AutocompleteInput label="SMART OBJECT BOOK" variant="standard" source="name" />
      </ReferenceInput>
    );
  }
  if (columns?.smartObjectType) {
    components.push(
      <ChipField label="SMART OBJECT TYPE" source="smartObjectType" key="smartObjectType"/>
    );
    filters.push(
      <TextInput label="SMART OBJECT TYPE" source="smartObjectType" variant="standard" />
    );
  }
  if (columns?.manufacturingStatus) {
    components.push(
      <ChipField label="MANUFACTURING STATUS" source="manufacturingStatus" key="manufacturingStatus"/>
    );
    filters.push(
      <TextInput label="MANUFACTURING STATUS" source="manufacturingStatus" variant="standard" />
    );
  }
  if (columns['type.nfc']) {
		components.push(
			<BooleanField label="TYPE NFC" source="type.nfc" key="type.nfc" /> 
		);
		filters.push(
			<BooleanInput label="TYPE NFC" source="type.nfc"/>
		);
	}
  if (columns['type.qr']) {
		components.push(
			<BooleanField label="TYPE QR" source="type.qr" key="type.qr" /> 
		);
		filters.push(
			<BooleanInput label="TYPE QR" source="type.qr"/>
		);
	}
  if (columns?.urlVersion) {
		components.push(
			<NumberField label="URL VERSION" source="urlVersion" key="urlVersion" />
		);
		filters.push(
			<NumberInput label="URL VERSION" source="urlVersion" variant="standard" />
		);
	}
	if (columns?.createdAt) {
		components.push(
			<DateField showTime label="CREATED AT" source="createdAt" key="createdAt" />
		);
		filters.push(
			<DateInput label="CREATED AT" source="createdAt" variant="standard" />
		);
	}
	if (columns?.createdBy) {
		components.push(
			<ReferenceField label="CREATED BY" source="createdBy" key="createdBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="CREATED BY" source="createdBy" reference="users" perPage={1000}>
				<AutocompleteInput label="CREATED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.editedAt) {
		components.push(
			<DateField showTime label="EDITED AT" source="editedAt" key="editedAt" />
		);
		filters.push(
			<DateInput label="EDITED AT" source="editedAt" variant="standard" />
		);
	}
	if (columns?.editedBy) {
		components.push(
			<ReferenceField label="EDITED BY" source="editedBy" key="editedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="EDITED BY" source="editedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="EDITED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.deletedAt) {
		components.push(
			<DateField showTime label="DELETED AT" source="deletedAt" key="deletedAt" /> 
		);
		filters.push(
			<DateInput label="DELETED AT" source="deletedAt" variant="standard" />
		);
	}
	if (columns?.deletedBy) {
		components.push(
			<ReferenceField label="DELETED BY" source="deletedBy" key="deletedBy" reference="users">
				<TextField source="name" />
			</ReferenceField>
		);
		filters.push(
			<ReferenceInput label="DELETED BY" source="deletedBy" reference="users" perPage={1000}>
				<AutocompleteInput label="DELETED BY" variant="standard" source="name" />
			</ReferenceInput>
		);
	}
	if (columns?.isDeleted) {
		components.push(
			<BooleanField label="IS DELETED" source="isDeleted" key="isDeleted" /> 
		);
		filters.push(
			<BooleanInput label="IS DELETED" source="isDeleted"/>
		);
	}
  if (columns?.isStatic) {
		components.push(
			<BooleanField label="IS STATIC" source="isStatic" key="isStatic" /> 
		);
		filters.push(
			<BooleanInput label="IS STATIC" source="isStatic"/>
		);
	}

	const bulkActionButtons = <BulkActionButtons />;
	const actions = <Actions/>;
	const pagination = <Pagination context={filterContext} />;
	const empty = false;

	return (
		<List empty={empty} filters={filters} actions={actions} pagination={pagination}>
			<Datagrid size="medium" bulkActionButtons={bulkActionButtons}>
				{components}
				<EditButton size="medium" />
				<SoftDeleteButton />
			</Datagrid>
		</List>
	);
}
